import React, { useState, useContext, useCallback } from 'react'
import cn from 'classnames'

import AreaContext from '../../context/area'
import { STEPS } from './entity'
import {
  BlockedAccountStep,
  LoginStep,
  RestoringStep,
  RestoringSuccessStep,
  RegistrationStep,
} from './steps'
import * as styles from './login.module.less'
import CloseSvg from './img/close.inline.svg'

const Login = ({
  modalOnClose,
  analyticsParams,
  setSavedEmail = () => {},
  savedEmail,
}) => {
  const [email, setEmail] = useState('')
  const [step, setStep] = useState(STEPS.login)
  const [curator, setCurator] = useState(null)

  const { area } = useContext(AreaContext)

  const resetStepOnClose = useCallback(() => {
    email
      ? setSavedEmail(email)
      : step === 'login' && document.getElementById('firstName')
      ? setSavedEmail(document.getElementById('firstName').value)
      : step === 'restore' && document.getElementById('email')
      ? setSavedEmail(document.getElementById('email').value)
      : null
    setStep(STEPS.login)
    modalOnClose()
  }, [setStep, setSavedEmail])

  if (step === STEPS.registration) {
    return <RegistrationStep modalOnClose={modalOnClose} />
  }

  return (
    <div className={cn('container-padding--hr', `area-${area}`)}>
      <div className={styles.header}>
        {typeof modalOnClose === 'function' && (
          <button onClick={resetStepOnClose} className={styles.close}>
            <CloseSvg />
          </button>
        )}
      </div>

      <div className={styles.content}>
        {step === STEPS.restoringSuccess ? (
          <RestoringSuccessStep email={email} setStep={setStep} />
        ) : step === STEPS.restoring ? (
          <RestoringStep
            email={email}
            setStep={setStep}
            setEmail={setEmail}
            analyticsParams={analyticsParams}
          />
        ) : step === STEPS.blocked ? (
          <BlockedAccountStep setStep={setStep} curator={curator} />
        ) : (
          <LoginStep
            setStep={setStep}
            setEmail={setEmail}
            setCurator={setCurator}
            analyticsParams={analyticsParams}
            setSavedEmail={setSavedEmail}
            savedEmail={savedEmail}
          />
        )}
      </div>
    </div>
  )
}

export default Login
