import React, { useState } from 'react'
import { AnalyticsProvider } from '../context/ga'
import LoginForm from '../components/Login'
import { analyticsLogInParams } from '../components/HeadSection/Header/entity'

import { AreaProvider } from '../context/area'

const Login = () => {
  const [savedEmail, setSavedEmail] = useState(null)

  return (
    <AreaProvider>
      <AnalyticsProvider>
        <LoginForm
          analyticsParams={analyticsLogInParams}
          setSavedEmail={setSavedEmail}
          savedEmail={savedEmail}
        />
      </AnalyticsProvider>
    </AreaProvider>
  )
}

export default Login
