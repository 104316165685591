export const analyticsParams = {
  email: {
    event: 'eventGA',
    eventCategory: 'Application',
    eventAction: 'fieldFilling',
    eventLabel: 'email',
    clickText: '',
  },
  city: {
    event: 'eventGA',
    eventCategory: 'Application',
    eventAction: 'fieldFilling',
    eventLabel: 'city',
    clickText: '',
  },
  name: {
    event: 'eventGA',
    eventCategory: 'Application',
    eventAction: 'fieldFilling',
    eventLabel: 'name',
    clickText: '',
  },
  phoneNumber: {
    event: 'eventGA',
    eventCategory: 'Application',
    eventAction: 'fieldFilling',
    eventLabel: 'phone',
    clickText: '',
  },
}

export const analyticsLogInParams = {
  login: {
    eventCategory: 'login',
    eventAction: 'fieldFilling',
    eventLabel: 'username',
  },
  password: {
    eventCategory: 'login',
    eventAction: 'fieldFilling',
    eventLabel: 'password',
  },
}
